import { HttpClient } from '@angular/common/http';
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@environment/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as SentryAngular from '@sentry/angular';
import * as Sentry from '@sentry/capacitor';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

if (environment.production) {
    Sentry.init(
        {
            dsn: 'https://23bc7d2bd2b848609b5cd5d3c591b586@o4505025622441984.ingest.sentry.io/4505459291324416',
            release: 'daiware.dpupps.com@1',
            dist: '1',
            enableNative: false,
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            integrations: [SentryAngular.replayIntegration()],
        },
        SentryAngular.init,
    );
}

if (environment.production) {
    enableProdMode();

    if (!environment.enableLogs) {
        // Directamente se anula la funcionalidad de los métodos de la consola
        ['log', 'info', 'debug'].forEach((methodName) => {
            (console as any)[methodName] = () => {};
        });
    }
} else {
    console.log('Debug mode enabled');
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

bootstrapApplication(AppComponent, appConfig).catch((err) => {
    console.error(err);
});
