import { isPlatformBrowser } from '@angular/common';
import { ErrorHandler, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { environment } from '@environment/environment';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/browser';
import { LoadingService } from '@services/loading.service';
import { SweetAlertsService } from '@services/sweet-alerts.service';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
    private readonly platformId = inject(PLATFORM_ID);

    constructor(
        private sweetAlertsService: SweetAlertsService,
        private translateService: TranslateService,
        private loadService: LoadingService,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            window.addEventListener('error', (event) => {
                this.handleOutsideAngularError(event.error);
            });
        }
    }

    handleError(error: any) {
        console.log('Global error captured:', error);
        if (environment.production) {
            Sentry.captureException(error.originalError || error);
        }

        setTimeout(() => {
            this.loadService.loadingHide();
        }, 3000);
    }

    private handleOutsideAngularError(error: Error) {
        console.log('Outside Angular error:', error);
        if (environment.production) {
            Sentry.captureException(error);
        }
        this.sweetAlertsService.show('error', this.translateService.instant('SHARED.ERROR'), this.translateService.instant('AUTHENTICATION.UNESPECTED_ERROR'), 'dark');
    }
}
