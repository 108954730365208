import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClient, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, importProvidersFrom, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, provideClientHydration, withEventReplay, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { Router, provideRouter, withComponentInputBinding } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { HttpErrorInterceptor } from '@interceptors/http-error.interceptor';
import { IonicModule } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as SentryAngular from '@sentry/angular';
import { ApiService } from '@services/api/api.service';
import { AudioToTextAPIService } from '@services/api/apis/audio-to-text-api.service';
import { AvatarsApiService } from '@services/api/apis/avatars-api.service';
import { ChatApiService } from '@services/api/apis/chat-api.service';
import { ChatLlmApiService } from '@services/api/apis/chat-llm-api.service';
import { LanguagesApiService } from '@services/api/apis/languages-api.service';
import { PremiumApiService } from '@services/api/apis/premium-api.service';
import { PromptsApiService } from '@services/api/apis/prompts-api.service';
import { PurchasesServiceApi } from '@services/api/apis/purchases-api.service';
import { RabbitmqApiService } from '@services/api/apis/rabbitmq-api.service';
import { RefersApiService } from '@services/api/apis/refers-api.service';
import { RewardsApiService } from '@services/api/apis/rewards-api.service';
import { StorageApiService } from '@services/api/apis/storage-api.service';
import { TextToSpeechAPIService } from '@services/api/apis/text-to-speech-api.service';
import { TransactionsApiService } from '@services/api/apis/transactions-api.service';
import { UserApiService } from '@services/api/apis/user-api.service';
import { VisionApiService } from '@services/api/apis/vision-api.service';
import { AppVersionService } from '@services/appVersion.service';
import { AvatarsService } from '@services/avatars.service';
import { CallService } from '@services/calls/call.service';
import { ChatHistoryService } from '@services/chat-history.service';
import { MemoryService } from '@services/custom/memory.service';
import { AuthenticationService } from '@services/firebase/authentication.service';
import { IntermediateService } from '@services/firebase/intermediate.service';
import { UserDataService } from '@services/firebase/user-data.service';
import { AudioToTextService } from '@services/ia/audio-to-text.service';
import { ChatLLM } from '@services/ia/chat-llm.service';
import { TextToSpeechService } from '@services/ia/text-to-speech.service';
import { VisionService } from '@services/ia/vision.service';
import { LayoutService } from '@services/layout.service';
import { LoadingService } from '@services/loading.service';
import { MessagesService } from '@services/messages.service';
import { PersonalityService } from '@services/personality.service';
import { PremiumService } from '@services/purchases/premium.service';
import { PurchasesService } from '@services/purchases/purchases.service';
import { RevenuecatService } from '@services/purchases/revenuecat.service';
import { TokensService } from '@services/purchases/tokens.service';
import { PushNotificationsService } from '@services/push-notifications.service';
import { RateAppService } from '@services/rateApp.service';
import { RewardsService } from '@services/rewards.service';
import { ConfigService } from '@services/settings/config.service';
import { LanguageService } from '@services/settings/languages.service';
import { NetworkService } from '@services/settings/network.service';
import { SettingsService } from '@services/settings/settings.service';
import { ToolsService } from '@services/settings/tools.service';
import { GlobalErrorHandlerService } from '@services/shared/global-error-handle.service';
import { StatusBarService } from '@services/shared/statusBar.service';
import { SuccessLottieService } from '@services/sucess.service';
import { SweetAlertsService } from '@services/sweet-alerts.service';
import { FirebaseModule } from '@shared/modules/firebase.module';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { provideLottieOptions } from 'ngx-lottie';
import { MessageService } from 'primeng/api';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(withFetch(), withInterceptors([HttpErrorInterceptor])),
        provideAnimations(),
        importProvidersFrom(
            IonicModule,
            BrowserModule,
            BrowserAnimationsModule,
            FormsModule,
            ReactiveFormsModule,
            FirebaseModule,
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient],
                },
            }),
        ),
        provideRouter(routes, withComponentInputBinding()),
        provideClientHydration(
            withHttpTransferCacheOptions({
                includePostRequests: true,
            }),
            withEventReplay(),
        ),
        provideLottieOptions({
            player: () => import('lottie-web'),
        }),
        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000',
        }),
        importProvidersFrom(NgxGoogleAnalyticsModule.forRoot('G-5675EFKM9R')),
        importProvidersFrom(NgxGoogleAnalyticsRouterModule),
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        {
            provide: SentryAngular.TraceService,
            deps: [Router],
        },
        LayoutService,
        SettingsService,
        MessageService,
        NetworkService,
        LoadingService,
        PushNotificationsService,
        RevenuecatService,
        ToolsService,
        ChatHistoryService,
        ConfigService,
        LanguageService,
        TransactionsApiService,
        SettingsService,
        RewardsService,
        LoadingService,
        RateAppService,
        PremiumService,
        TokensService,
        MessagesService,
        AuthenticationService,
        PersonalityService,
        MemoryService,
        VisionService,
        ChatLLM,
        TextToSpeechService,
        TextToSpeechAPIService,
        SweetAlertsService,
        CallService,
        AudioToTextService,
        ApiService,
        PromptsApiService,
        ChatApiService,
        LanguagesApiService,
        RewardsApiService,
        PremiumApiService,
        UserApiService,
        UserDataService,
        IntermediateService,
        ChatLlmApiService,
        RabbitmqApiService,
        VisionApiService,
        AudioToTextAPIService,
        AvatarsService,
        AvatarsApiService,
        StatusBarService,
        AppVersionService,
        PurchasesService,
        PurchasesServiceApi,
        SuccessLottieService,
        RefersApiService,
        StorageApiService,
    ],
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
