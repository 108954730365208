import { Component } from '@angular/core';
import { SharedModule } from '@shared/shared.module';

@Component({
    selector: 'app-service-worker',
    templateUrl: './service-worker.component.html',
    styleUrls: ['./service-worker.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class ServiceWorkerReloadComponent {
    constructor() {} // private update: SwUpdate, // private messageService: MessageService,

    ngOnInit() {
        // if (this.update.isEnabled) {
        //     this.update.versionUpdates.subscribe((event) => {
        //         if (event.type === 'VERSION_READY') {
        //             this.presentUpdateToast();
        //         }
        //     });
        // }
    }

    presentUpdateToast() {
        // this.messageService.add({
        //     key: 'update',
        //     severity: 'warn',
        //     summary: 'New Version Available',
        //     sticky: true,
        //     detail: 'Click to reload.',
        //     life: 20000, // 20 seconds
        // });
        // console.log('New Version Available SW');
        // this.onReload();
    }

    onReload() {
        // this.update
        //     .activateUpdate()
        //     .then(() => window.location.reload())
        //     .catch((err) => console.log('Error activating update:', err));
    }
}
