import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

export const HttpErrorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) =>
    next(req).pipe(
        catchError((error: HttpErrorResponse) => {
            const errorMessage = `Error code: ${error.status}, message: ${error.message}`;
            return throwError(errorMessage);
        }),
    );
