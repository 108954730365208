import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@services/firebase/authentication.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NoAuthGuard {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
    ) {}

    canActivate(): Observable<boolean> {
        return this.authenticationService.currentUserSubject.pipe(
            take(1),
            map((user) => {
                if (user) {
                    this.router.navigate(['/dashboard/chat']);
                    return false;
                } else {
                    return true;
                }
            }),
        );
    }
}
