import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { environment } from '@environment/environment';

@NgModule({
    imports: [AngularFireModule.initializeApp(environment.firebase), AngularFireAuthModule, AngularFireStorageModule, AngularFireDatabaseModule],
    exports: [AngularFireModule, AngularFireAuthModule, AngularFireStorageModule, AngularFireDatabaseModule],
    declarations: [],
})
export class FirebaseModule {}
