import { Component, OnInit } from '@angular/core';
import { LoadingService } from '@services/loading.service';
import { NetworkService } from '@services/settings/network.service';
import { SharedModule } from '@shared/shared.module';

@Component({
    selector: 'app-network',
    styleUrls: ['./network.component.scss'],
    templateUrl: './network.component.html',
    standalone: true,
    imports: [SharedModule],
})
export class NetworkComponent implements OnInit {
    networkStatus: boolean | null = true;
    isFirstCheck: boolean = true;

    constructor(
        public networkService: NetworkService,
        private loadingService: LoadingService,
    ) {
        this.networkService.logCurrentNetworkStatus();
        this.networkService.checkNetwork();
    }

    ngOnInit() {
        this.networkService.networkStatus$.subscribe((status) => {
            this.networkStatus = status;

            if (this.isFirstCheck) {
                this.isFirstCheck = false;
            } else {
                if (status === null) {
                    this.loadingService.loadingShowSpinner(); // Mostrar el spinner si el estado es nulo después de la primera comprobación
                } else {
                    this.loadingService.loadingHideSpinner(); // Ocultar el spinner si la red está activa o inactiva
                }
            }
        });
    }
}
