import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingService } from '@services/loading.service';
import { StatusBarService } from '@services/shared/statusBar.service';
import { SharedModule } from '@shared/shared.module';
import { filter } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ToolsService } from '../../../core/services/settings/tools.service';

@Component({
    selector: 'statusbar',
    templateUrl: './statusbar.component.html',
    styleUrls: ['./statusbar.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class StatusBarComponent {
    platform = '';
    statusBarColor: string = '#fafcff'; // Default color
    isLoading: boolean = false;
    shouldShowStatusBarColor: boolean = false;
    private readonly isBrowser: boolean;
    environment: any = environment;

    constructor(
        private statusBarService: StatusBarService,
        private loadingService: LoadingService,
        private renderer: Renderer2,
        private router: Router,
        private toolsService: ToolsService,
        @Inject(PLATFORM_ID) platformId: Object,
    ) {
        this.isBrowser = isPlatformBrowser(platformId);

        if (this.isBrowser) {
            this.initializeSafeArea();
        }

        this.initializeStatusBar();
    }

    async initializeSafeArea() {
        try {
            const { SafeArea } = await import('@capacitor-community/safe-area');
            SafeArea.enable({
                config: {
                    customColorsForSystemBars: true,
                    statusBarColor: '#00000000', // transparent
                    statusBarContent: 'light',
                    navigationBarColor: '#00000000', // transparent
                    navigationBarContent: 'light',
                },
            });
        } catch (error) {
            console.error('Error al cargar el módulo safe-area:', error);
        }
    }

    async initializeStatusBar() {
        this.platform = await this.toolsService.getPlatform();

        this.statusBarService.statusBarColorSubject.subscribe((color) => {
            this.statusBarColor = color;
            this.updateShouldShowStatusBarColor();
        });

        this.loadingService.isLoading.subscribe((loading) => {
            this.isLoading = loading;
            this.updateShouldShowStatusBarColor();
        });

        if (this.isBrowser && this.platform) {
            this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
                this.statusBarColor = this.statusBarService.getStatusBarColorForRoute(event.urlAfterRedirects);
                console.log('Status Bar Color:', this.statusBarColor);
                this.adjustGlobalStylesForIos();
                this.updateShouldShowStatusBarColor();
            });
        }
    }

    adjustGlobalStylesForIos() {
        // Add 'top' property to all elements with 'addTopBarIos' class when on iOS
        if (!this.isBrowser || this.platform !== 'ios') return;

        const paddingTopValue = 'var(--safe-area-inset-top)';
        const elements = document.querySelectorAll('.addTopBariOS');

        elements.forEach((element) => {
            this.renderer.setStyle(element, 'padding-top', paddingTopValue);
        });
    }

    updateShouldShowStatusBarColor() {
        this.shouldShowStatusBarColor = this.isLoading || this.statusBarColor !== this.statusBarService.androidStatusBarColors['default'].color;
    }
}
