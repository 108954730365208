import { Component } from '@angular/core';
import { LoadingService } from '@services/loading.service';
import { SharedModule } from '@shared/shared.module';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-spinners',
    templateUrl: './spinners.component.html',
    styleUrls: ['./spinners.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class SpinnersComponent {
    isLoading$: Observable<boolean>;
    isLoadingSpinner$: Observable<boolean>;

    constructor(public loadingService: LoadingService) {
        this.isLoading$ = this.loadingService.isLoading;
        this.isLoadingSpinner$ = this.loadingService.isLoadingSpinner;
    }
}
