import { Component } from '@angular/core';
import { Event, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AuthenticationService } from '@services/firebase/authentication.service';
import { PushNotificationsService } from '@services/push-notifications.service';
import { LanguageService } from '@services/settings/languages.service';
import { ToolsService } from '@services/settings/tools.service';
import { StatusBarService } from '@services/shared/statusBar.service';
import { ComingSoonComponent } from '@shared/components/coming-soon/coming-soon.component';
import { NetworkComponent } from '@shared/components/network/network.component';
import { ServiceWorkerReloadComponent } from '@shared/components/service-worker/service-worker.component';
import { SpinnersComponent } from '@shared/components/spinners/spinners.component';
import { StatusBarComponent } from '@shared/components/statusbar/statusbar.component';
import { SharedModule } from '@shared/shared.module';
import { inject } from '@vercel/analytics';
import { injectSpeedInsights } from '@vercel/speed-insights';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { PrimeNGConfig } from 'primeng/api';
import { filter } from 'rxjs';
import { routeTransitionAnimations } from '../assets/layout/styles/theme/route-transition-animations';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [SharedModule, RouterOutlet, StatusBarComponent, NetworkComponent, ServiceWorkerReloadComponent, ComingSoonComponent, SpinnersComponent],
    animations: [routeTransitionAnimations],
})
export class AppComponent {
    constructor(
        private primengConfig: PrimeNGConfig,
        private pushNotifications: PushNotificationsService,
        private toolsService: ToolsService,
        private languageService: LanguageService,
        private authenticationService: AuthenticationService,
        private statusBarService: StatusBarService,
        private router: Router,
        private $gaService: GoogleAnalyticsService,
    ) {
        this.initializeApp();
        injectSpeedInsights();
        inject();
    }

    // Método para obtener los datos de animación de la ruta
    getRouteAnimationData(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }

    ngOnInit() {
        this.primengConfig.ripple = true;

        this.primengConfig.zIndex = {
            modal: 1100, // dialog, sidebar
            overlay: 1000, // dropdown, overlaypanel
            menu: 1000, // overlay menus
            tooltip: 1100, // tooltip
            'p-toast': 9999, // toast
        };

        this.router.events.pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.$gaService.pageView(event.urlAfterRedirects);
        });
    }

    private async initializeApp(): Promise<void> {
        console.log('initilizeApp');

        this.statusBarService.initStatusBar();
        this.authenticationService.initializeFirebase();
        this.languageService.setLanguageDefaultApp();
        this.toolsService.monitorAppLifecycle();
        this.toolsService.handleBackButtonPress();
        this.pushNotifications.OneSignalInit();
    }
}
