<p-toast position="bottom-right" key="update" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-col" style="flex: 3">
            <div class="text-center col-2">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
            </div>
            <div class="text-center col-7">
                <h4>{{ message.summary }}</h4>
                <p>{{ message.detail }}</p>
            </div>
            <div class="text-center col-3">
                <p-button icon="pi pi-refresh" (click)="onReload()" styleClass="buttonRefresh"></p-button>
            </div>
        </div>
    </ng-template>
</p-toast>
