import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';

@Injectable()
export class ConfigService {
    getEnvironment() {
        let prefix;

        if (!environment.production) {
            prefix = '_dv';
        } else {
            prefix = '_pd';
        }

        return prefix;
    }
}
