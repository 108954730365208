<!-- Spinner used to main and important process-->
@if (isLoading$ | async) {
    <div class="spinnerBackground">
        <span class="spinnerInitialIndex"></span>
    </div>
}

<!-- Spinner used to network state -->
@if (isLoadingSpinner$ | async) {
    <div class="smallSpinnerContainer">
        <div class="smallSpinner"></div>
    </div>
}
