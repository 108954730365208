import { Injectable } from '@angular/core';

@Injectable()
export class PushNotificationsService {
    // Call this function when your app starts
    async OneSignalInit(): Promise<void> {
        // if (environment.production) {
        //     // Uncomment to set OneSignal device logging to VERBOSE
        //     // OneSignal.setLogLevel(6, 0);
        //     // NOTE: Update the setAppId value below with your OneSignal AppId.
        //     OneSignal.setAppId('9c18ade1-bad5-4cd9-819c-b6bf80d0f080');
        //     OneSignal.setNotificationOpenedHandler(function (jsonData) {
        //         console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
        //     });
        //     // Prompts the user for notification permissions.
        //     //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
        //     OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
        //         console.log('User accepted notifications: ' + accepted);
        //     });
        // }
    }
}
