import { Routes } from '@angular/router';
import { NoAuthGuard } from '@guards/no-auth.guard';

export const routes: Routes = [
    {
        path: '',
        children: [{ path: '', redirectTo: '/dashboard/chat', pathMatch: 'full' }], // Ponemos dashboard, asi no pasa por home si ya esta logeado y no se veria la pantalla de login, iria directo al dashboard, si no esta logeado, iria a home
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./components/dashboard/dashboard.routes'),
        data: { animation: 'DashboardPage' },
    },
    {
        path: 'home',
        loadChildren: () => import('./components/home/home.routes'),
        canActivate: [NoAuthGuard],
        data: { animation: 'HomePage' },
    },
    {
        path: 'test',
        loadChildren: () => import('./components/test/test.routes'),
        data: { animation: 'TestPage' },
    },
    {
        path: 'doc',
        loadChildren: () => import('./components/doc/doc.routes'),
        data: { animation: 'DocPage' },
    },
    {
        path: 'maintenance',
        loadChildren: () => import('./shared/components/maintenance/maintenance.routes'),
        data: { animation: 'MaintenancePage' },
    },
    {
        path: 'notfound',
        loadChildren: () => import('./shared/components/notfound/notfound.routes'),
        data: { animation: 'NotFoundPage' },
    },
    {
        path: '**',
        redirectTo: 'notfound',
    },
];
