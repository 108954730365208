<statusbar></statusbar>
<app-spinners></app-spinners>
<div [@routeAnimations]="getRouteAnimationData(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
</div>
<app-network></app-network>
<app-service-worker></app-service-worker>
<coming-soon></coming-soon>
<div style="z-index: 999999999 !important; position: absolute">
    <!-- ! No eliminar, toast BackButton -->
    <p-toast position="bottom-center"></p-toast>
</div>

<!-- ! No eliminar, Lottie Loader -->
<div id="lottie"></div>
